import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import CryptoJS from "crypto-js";
async function encodePassword(username, password) {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.SHA256(password + username.toLowerCase())
  );
}

var host = window.location.protocol + "//" + window.location.hostname;

const iRacingClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${host}:8000/iracing`
      : "/iracing",
  crossDomain: "true",
  withCookie: true,
  timeout: 1000 * 30,
  responseType: "json",
  responseEncoding: "utf8",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  },
});

export default iRacingClient;

export const login = async (username, password) =>
  await iRacingClient
    .post("/auth", {
      email: username,
      password: await encodePassword(username, password),
    })
    .then(({ data }) => data);

export const useMemberInfo = disabled => {
  return useQuery(
    ["member"],
    async () => {
      const { data } = await iRacingClient.get(`/data/member/info`);
      return data;
    },
    { enabled: !disabled }
  );
};

export const useCarClasses = disabled => {
  return useQuery(
    ["car_classes"],
    async () => {
      const { data } = await iRacingClient.get(`/data/carclass/get`);
      return data;
    },
    { enabled: !disabled }
  );
};

export const useUserSeries = disabled => {
  return useQuery(
    ["user_series"],
    async () => {
      const { data: seriesData } = await iRacingClient.get(`/data/series/get`);
      const { data: seasonsData } = await iRacingClient.get(
        `data/series/seasons`
      );
      return seriesData.map(series => ({
        ...series,
        ...seasonsData.find(season => season.series_id === series.series_id),
      }));
    },
    { enabled: !disabled }
  );
};

export const useLicenses = disabled => {
  return useQuery(
    ["licenses"],
    async () => {
      const { data } = await iRacingClient.get(`/data/lookup/licenses`);
      return data.reduce((acc, license) => {
        acc[license.license_group] = license.group_name.replace("Class ", "");
        return acc;
      }, {});
    },
    { enabled: !disabled }
  );
};

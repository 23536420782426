import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { fromPairs, groupBy, sortBy, toPairs } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Loading from "./Loading";
import {
  login,
  useCarClasses,
  useLicenses,
  useMemberInfo,
  useUserSeries,
} from "./iRacingClient";
import papayrusClient, { hashUsername, useSessions } from "./papayrusClient";

import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

const EMOJI_DICT = {
  oval: "⚫",
  sports_car: "🚗",
  dirt_oval: "🟤",
  formula_car: "🏎️",
  dirt_road: "🛣️",
};

// take availability and turn it into windows for each day of the week where there are evnets
// it should be an array of arrays each array should be the day of the week and each object should be each event in that day
const convertAvailabilityToWeeks = availability => {
  const days = Array.from({ length: 7 }, (_, i) => i);
  return days.map(day => {
    return availability
      .filter(event => new Date(event.start).getDay() === day)
      .map(event => ({
        start: moment(event.start),
        end: moment(event.end),
      }));
  });
};

const convertWeeksToAvailability = weeks => {
  const currentWeek = moment().utc().startOf("week");
  return weeks
    .map((week, i) =>
      week.map(event => {
        const startMoment = moment.utc(event.start, "HH:mm:ss");
        const endMoment = moment.utc(event.end, "HH:mm:ss");
        return {
          ...event,
          start: currentWeek
            .clone()
            .utc()
            .add(i, "days")
            .hour(startMoment.hour())
            .minute(startMoment.minute())
            .second(startMoment.second())
            .toDate(),
          end: currentWeek
            .clone()
            .utc()
            .add(startMoment > endMoment ? i + 1 : i, "days")
            .hour(endMoment.hour())
            .minute(endMoment.minute())
            .second(endMoment.second())
            .toDate(),
        };
      })
    )
    .flat();
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const [calendarPath, setCalendarPath] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [availabilityIsOpen, setAvailabilityIsOpen] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [onlyFixed, setOnlyFixed] = useState(false);
  const [onlyEligible, setOnlyEligible] = useState(false);
  const [onlyOwnedCars, setOnlyOwnedCars] = useState(false);
  const [onlyOwnedTracks, setOnlyOwnedTracks] = useState(false);
  const {
    data: memberData,
    status: memberStatus,
    error: memberError,
  } = useMemberInfo(!loggedIn);

  const {
    data: seriesData,
    status: seriesStatus,
    error: seriesError,
  } = useUserSeries(!loggedIn);

  const {
    data: carClasses,
    status: carClassesStatus,
    error: carClassesError,
  } = useCarClasses(!loggedIn);

  const {
    data: licenseData,
    status: licenseStatus,
    error: licenseError,
  } = useLicenses(!loggedIn);
  const {
    data: sessionsData,
    status: sessionsStatus,
    error: sessionsError,
  } = useSessions(!loggedIn);
  const submitLogin = async ({ username, password }, { setSubmitting }) => {
    setUsername(username);
    const { selected_series, availability } = await login(username, password);
    setSelectedSeries(selected_series || []);

    setAvailability(
      availability
        ? convertWeeksToAvailability(availability)
        : [
            [0, 1, 2, 3, 4, 5, 6].map(day => [
              {
                title: "Available",
                start: moment()
                  .set({ hour: 0, minute: 0, second: 0 })
                  .add(day, "days")
                  .toISOString(),
                end: moment()
                  .set({ hour: 23, minute: 59, second: 59 })
                  .add(day, "days")
                  .toISOString(),
              },
            ]),
          ]
    );
    setLoggedIn(true);
    setSubmitting(false);
  };

  const getCalendarPath = async () => {
    const { data } = await papayrusClient.post(`/cal_path/`, {
      user_hash: hashUsername(username),
      selected_series: selectedSeries,
      availability: convertAvailabilityToWeeks(availability).map(day =>
        day.map(event => ({
          // convert to UTC and format as HH:mm:ss
          start: event.start.utc().format("HH:mm:ss"),
          end: event.end.utc().format("HH:mm:ss"),
        }))
      ),
    });
    setCalendarPath(
      window.location.protocol + "//" + window.location.hostname + "/" + data
    );
  };
  const makeTrackLicense = useCallback(
    (track_types, license_group) =>
      track_types?.map(({ track_type }) => EMOJI_DICT[track_type])?.join(" ") +
      " " +
      licenseData[license_group][0],
    [licenseData]
  );
  const ownedCars = memberData?.car_packages
    ?.map(pkg => pkg.content_ids)
    ?.flat();
  const ownedClasses = Object.fromEntries(
    carClasses?.map(carClass => [
      carClass?.car_class_id,
      // intersection of owned cars and cars in class
      carClass?.cars_in_class
        ?.map(car => car?.car_id)
        .filter(car => ownedCars?.includes(car)).length > 0,
    ]) || []
  );
  const ownedTracks = memberData?.track_packages
    ?.map(pkg => pkg.content_ids)
    ?.flat();

  const convertedAvailability = useMemo(
    () => convertAvailabilityToWeeks(availability),
    [availability]
  );
  const groupedSeries = useMemo(
    () =>
      Object.values(
        fromPairs(
          sortBy(
            toPairs(
              groupBy(
                seriesData
                  ?.filter(({ eligible }) => !onlyEligible || eligible)
                  ?.filter(({ fixed_setup }) => !onlyFixed || fixed_setup)
                  ?.filter(
                    ({ car_class_ids }) =>
                      !onlyOwnedCars ||
                      car_class_ids.some(carClass => ownedClasses[carClass])
                  )
                  ?.filter(
                    ({ schedules }) =>
                      !onlyOwnedTracks ||
                      schedules.every(({ track }) =>
                        ownedTracks.includes(track.track_id)
                      )
                  )
                  ?.map(
                    ({ track_types, license_group, schedules, ...rest }) => ({
                      trackLicense: makeTrackLicense(
                        track_types,
                        license_group
                      ),
                      track_types,
                      license_group,
                      track_ids: schedules.map(sched => sched.track.track_id),
                      schedules,
                      ...rest,
                    })
                  ),
                series => series.trackLicense
              ),
              0
            )
          )
        ) || {}
      ),
    [
      seriesData,
      onlyEligible,
      onlyFixed,
      onlyOwnedCars,
      ownedClasses,
      onlyOwnedTracks,
      ownedTracks,
      makeTrackLicense,
    ]
  );
  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      if (allDay && !droppedOnAllDaySlot) {
        event.allDay = false;
      }

      setAvailability(prev => {
        const existing = prev.find(ev => ev.id === event.id) ?? {};
        const filtered = prev.filter(ev => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay: event.allDay }];
      });
    },
    [setAvailability]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setAvailability(prev => {
        const existing = prev.find(ev => ev.id === event.id) ?? {};
        const filtered = prev.filter(ev => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setAvailability]
  );
  return (
    <div className="bg-gray-900 w-screen h-screen text-white overflow-auto">
      <div className="top-0 sticky w-full bg-slate-950 py-2 px-3 flex z-10 opacity-90">
        <h1 className="text-white text-2xl font-semibold rounded bg-slate-800 shrink px-1">
          Papayrus Planner
        </h1>
      </div>
      <div className="flex mx-auto flex-col">
        {loggedIn ? (
          <Loading
            statuses={[
              memberStatus,
              seriesStatus,
              carClassesStatus,
              licenseStatus,
              sessionsStatus,
            ]}
            errors={[
              memberError,
              seriesError,
              carClassesError,
              licenseError,
              sessionsError,
            ]}
            className="flex flex-col gap-y-2 p-2">
            <div className="flex gap-x-2">
              <div className="p-2 rounded bg-slate-800">
                <b>Setup:</b>
                <button
                  className={`px-2 py-1 ml-1 rounded ${
                    onlyFixed ? "bg-orange-500" : "bg-slate-600"
                  }`}
                  onClick={() => setOnlyFixed(!onlyFixed)}>
                  {onlyFixed ? "Fixed" : "All"}
                </button>
              </div>
              <div className="p-2 rounded bg-slate-800">
                <b>Eligiblity:</b>
                <button
                  className={`px-2 py-1 ml-1 rounded ${
                    onlyEligible ? "bg-orange-500" : "bg-slate-600"
                  }`}
                  onClick={() => setOnlyEligible(!onlyEligible)}>
                  {onlyEligible ? "Eligible" : "All"}
                </button>
              </div>
              <div className="p-2 rounded bg-slate-800">
                <b>Cars:</b>
                <button
                  className={`px-2 py-1 ml-1 rounded ${
                    onlyOwnedCars ? "bg-orange-500" : "bg-slate-600"
                  }`}
                  onClick={() => setOnlyOwnedCars(!onlyOwnedCars)}>
                  {onlyOwnedCars ? "Owned" : "All"}
                </button>
              </div>
              <div className="p-2 rounded bg-slate-800">
                <b>Tracks:</b>
                <button
                  className={`px-2 py-1 ml-1 rounded ${
                    onlyOwnedTracks ? "bg-orange-500" : "bg-slate-600"
                  }`}
                  onClick={() => setOnlyOwnedTracks(!onlyOwnedTracks)}>
                  {onlyOwnedTracks ? "Owned" : "All"}
                </button>
              </div>
              <div className="p-2 rounded bg-slate-800">
                <button
                  className="px-2 py-1 rounded bg-orange-500"
                  onClick={() => setAvailabilityIsOpen(true)}>
                  Set Availability
                </button>
                <Dialog
                  open={availabilityIsOpen}
                  onClose={() => setAvailabilityIsOpen(false)}
                  className="relative z-50">
                  <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white bg-black bg-opacity-50">
                    <DialogPanel className="space-y-4 rounded drop-shadow bg-slate-800 p-6 opacity-100">
                      <DialogTitle className="font-bold text-xl">
                        Availability Settings
                      </DialogTitle>
                      <Description
                        className="text-sm text-gray-500
                      ">
                        Set your availability to only include sessions during
                        those times
                      </Description>
                      <DragAndDropCalendar
                        localizer={localizer}
                        defaultDate={new Date()}
                        defaultView="week"
                        events={availability}
                        style={{ height: "80vh", width: "60vw" }}
                        min={moment().startOf("week").toDate()}
                        max={moment().endOf("week").toDate()}
                        selectable
                        onSelectSlot={({ start, end }) => {
                          setAvailability([
                            ...availability,
                            {
                              title: "Available",
                              start,
                              end,
                              id: Math.random().toString(36).substring(7),
                            },
                          ]);
                        }}
                        onSelectEvent={event => {
                          // prompt user to delete event

                          const newAvailability = availability.filter(
                            ({ id }) => id !== event.id
                          );
                          setAvailability(newAvailability);
                        }}
                        onEventDrop={moveEvent}
                        onEventResize={resizeEvent}
                      />
                      <div className="flex gap-4">
                        <button onClick={() => setAvailabilityIsOpen(false)}>
                          Cancel
                        </button>
                        <button onClick={() => setAvailabilityIsOpen(false)}>
                          Deactivate
                        </button>
                      </div>
                    </DialogPanel>
                  </div>
                </Dialog>
              </div>
              <div className="p-2 rounded bg-slate-800">
                {calendarPath ? (
                  <div className="flex">
                    <input
                      type="text"
                      value={calendarPath}
                      readOnly
                      className="text-slate-800 rounded-l min-w-96"
                    />
                    <button
                      className="px-2 py-1 rounded-r bg-orange-500"
                      onClick={() => {
                        navigator.clipboard.writeText(calendarPath);
                      }}>
                      Copy
                    </button>
                    <button
                      className="px-2 py-1 ml-1 rounded bg-orange-500"
                      onClick={getCalendarPath}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <button
                    className="px-2 py-1 rounded bg-orange-500"
                    onClick={getCalendarPath}>
                    Get Calendar
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 w-full gap-x-2">
              <div className="col-span-4 gap-2 flex flex-col h-[80vh] overflow-y-auto">
                <h1 className="text-white text-2xl font-semibold">Series</h1>
                {groupedSeries?.map(sGroup => (
                  <React.Fragment key={sGroup[0]?.trackLicense}>
                    <Disclosure>
                      <DisclosureButton className="p-2 bg-slate-800 rounded text-white text-xl font-semibold text-left">
                        {sGroup[0]?.trackLicense}
                      </DisclosureButton>
                      <DisclosurePanel className="flex flex-col gap-y-2">
                        {sGroup?.map(series => (
                          <div
                            key={series.season_name}
                            className="p-2 bg-slate-600 rounded">
                            <h1 className="text-white text-xl font-semibold">
                              <button
                                className="text-sm mr-1 "
                                onClick={() => {
                                  if (
                                    selectedSeries?.includes(series.series_id)
                                  ) {
                                    setSelectedSeries(
                                      selectedSeries.filter(
                                        s => s !== series.series_id
                                      )
                                    );
                                  } else {
                                    setSelectedSeries([
                                      ...selectedSeries,
                                      series.series_id,
                                    ]);
                                  }
                                }}>
                                {selectedSeries.includes(series.series_id) ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5 text-green-600">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5 text-red-600">
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                )}
                              </button>
                              {series.series_name}{" "}
                              <span
                                className={`text-base font-light ${
                                  series.eligible
                                    ? "text-green-600"
                                    : "text-red-600"
                                }`}>
                                {series.eligible ? "Eligible" : "Not Eligible"}
                              </span>
                            </h1>
                            <div className="flex flex-col gap-y-1">
                              <div>{series.schedule_description}</div>
                              <div>
                                {series.track_ids.every(track_id =>
                                  ownedTracks.includes(track_id)
                                )
                                  ? "All Tracks Owned"
                                  : series.track_ids.some(track_id =>
                                      ownedTracks.includes(track_id)
                                    )
                                  ? "Some Tracks Owned"
                                  : "No Tracks Owned"}
                              </div>
                              <div>
                                Car Class{series.multiclass && "es"}:{" "}
                                {series.car_class_ids
                                  .map(
                                    carClass =>
                                      `${carClasses
                                        .find(c => c.car_class_id === carClass)
                                        ?.short_name.replace(" Class", "")} ${
                                        ownedClasses[carClass] ? "✔️" : "💰"
                                      }`
                                  )
                                  .join(", ")}
                              </div>
                              <div>Incidents: {series.incident_limit}</div>
                              {series.lucky_dog && <div>Lucky Dog</div>}
                              {series.start_zone && <div>Restart Zone</div>}
                              {series.is_heat_racing && <div>Heat Races</div>}
                              <Disclosure>
                                <DisclosureButton className="p-2 bg-slate-800 rounded text-white text-xl font-semibold text-left">
                                  Sessions
                                </DisclosureButton>
                                <DisclosurePanel className="flex flex-col gap-y-2">
                                  {series.schedules.map(schedule => (
                                    <div
                                      key={schedule.track.track_id}
                                      className="p-2 bg-slate-800 rounded">
                                      <h1 className="text-white text-xl font-semibold">
                                        {ownedTracks.includes(
                                          schedule.track.track_id
                                        ) ? (
                                          <span>✔️ </span>
                                        ) : (
                                          <span>💰 </span>
                                        )}
                                        {schedule.track.track_name}{" "}
                                        {schedule.track.config_name && (
                                          <span className="text-base font-light">
                                            {schedule.track.config_name}
                                          </span>
                                        )}
                                      </h1>
                                      {schedule.race_lap_limit && (
                                        <div>
                                          {schedule.race_lap_limit} Laps{" "}
                                          {series.num_opt_laps > 0 && (
                                            <span className="text-sm">
                                              {series.num_opt_laps} Joker Lap
                                              {series.num_opt_laps > 1
                                                ? "s"
                                                : ""}
                                            </span>
                                          )}
                                          {series.green_white_checkered_limit >
                                            0 && (
                                            <span className="text-sm">
                                              {
                                                series.green_white_checkered_limit
                                              }{" "}
                                              GWC
                                              {series.green_white_checkered_limit >
                                                1 && "s"}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                      {schedule.race_time_limit && (
                                        <div>
                                          {schedule.race_time_limit} Minutes
                                        </div>
                                      )}
                                      {schedule.full_course_cautions && (
                                        <div>Full Course Cautions</div>
                                      )}
                                      {schedule.start_type && (
                                        <div>{schedule.start_type} Starts</div>
                                      )}
                                      {schedule.restart_type && (
                                        <div>
                                          {schedule.restart_type} Restarts
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </DisclosurePanel>
                              </Disclosure>
                            </div>
                          </div>
                        ))}
                      </DisclosurePanel>
                    </Disclosure>
                  </React.Fragment>
                ))}
              </div>
              <div className="col-span-8">
                <h1 className="text-white text-2xl font-semibold">Calendar</h1>
                <Calendar
                  backgroundEvents={availability}
                  showMultiDayTimes
                  dayLayoutAlgorithm={"no-overlap"}
                  selectable
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView="week"
                  onDoubleClickEvent={event => {
                    console.log(event);
                  }}
                  events={sessionsData
                    ?.filter(session =>
                      selectedSeries?.includes(session.series_id)
                    )
                    ?.filter(session => {
                      const start = moment(session.start_time);
                      // compare with converted availability
                      const dayAvailability =
                        convertedAvailability[start.day()];
                      return dayAvailability.some(
                        ({ start: aStart, end: aEnd }) => {
                          const asFix = aStart.clone().set({
                            year: start.year(),
                            month: start.month(),
                            date: start.date(),
                          });
                          const aeFix = aEnd.clone().set({
                            year: start.year(),
                            month: start.month(),
                            date: start.date(),
                          });
                          // only compare time of day
                          return (
                            start.isSameOrAfter(asFix) &&
                            start.isSameOrBefore(aeFix)
                          );
                        }
                      );
                    })
                    ?.map(session => ({
                      title: session.ics_data.summary,
                      location: session.ics_data.location,
                      description: session.ics_data.description,
                      start: new Date(session.start_time),
                      end: new Date(session.end_time),
                    }))}
                  // style={{ height: "100vh" }}
                />
              </div>
            </div>
          </Loading>
        ) : (
          <div className="mx-auto rounded p-2 bg-slate-600 mt-2 min-w-96 flex flex-col">
            <h1 className="text-white text-2xl font-semibold">
              Login to iRacing
            </h1>
            <Formik
              initialValues={{ username: "", password: "" }}
              onSubmit={submitLogin}>
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-y-2">
                  <div className="flex flex-col">
                    <label htmlFor="username">E-Mail Address</label>
                    <Field
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="mx-1 px-1 rounded text-black"
                    />
                    <ErrorMessage name="username" component="div" />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="mx-1 px-1 rounded text-black"
                    />
                    <ErrorMessage name="password" component="div" />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-orange-500 disabled:saturate-50 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                    {isSubmitting ? "Loading..." : "Login"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;

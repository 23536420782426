import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import CryptoJS from "crypto-js";

var host = window.location.protocol + "//" + window.location.hostname;

export const papayrusClient = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? `${host}:8000/api` : "/api",
  crossDomain: "true",
  withCookie: true,
  timeout: 1000 * 30,
  responseType: "json",
  responseEncoding: "utf8",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

export default papayrusClient;

export const useSessions = disabled => {
  return useQuery(
    ["sessions"],
    async () => {
      const { data } = await papayrusClient.get(`/sessions/`);
      return data;
    },
    {
      enabled: !disabled,
    }
  );
};

export const useCalendarPath = (
  user_hash,
  selected_series,
  start_after = null,
  end_before = null
) => {
  return useQuery(
    ["calendar", user_hash, selected_series, start_after, end_before],
    async () => {
      const { data } = await papayrusClient.post(`/cal_path/`, {
        user_hash: user_hash,
        selected_series: selected_series,
        start_after: start_after,
        end_before: end_before,
      });
      return data;
    }
  );
};

export const hashUsername = username =>
  CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(username.toLowerCase()))
    .replace("+", "-")
    .replace("/", "_");
